<script>
import PageHeader from "@/components/page-header";
import axios from "axios";
import {required} from "vuelidate/lib/validators";
import {paginationHelper} from "@/helpers/pagination-helper";
import Swal from "sweetalert2";
import {swalHelper} from "@/helpers/swal-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import VueSlideBar from "vue-slide-bar";
import {routerHelper} from "@/helpers/router-helper";
import EcatLightboxGallery from "@/components/ecat-lightbox-gallery.vue";
import ShopProductLimitModal from "@/components/shop-product-limit-modal.vue";
import {productHelper} from "@/helpers/product-helper";
import CustomCardSubtitle from "../custom-card/custom-card-subtitle.vue";
import Switches from "vue-switches";
import Multiselect from "vue-multiselect";
import ShopProductCard from "@/components/product/shop-product-card.vue";
import {toastHelper} from "@/helpers/toast-helper";
import confirmModal from "@/components/confirm-modal.vue";
import VirtualList from 'vue-virtual-scroll-list'
import ProductPriceDetailModal from "@/components/product/product-price-detail-modal.vue";
import AddProductsToShopModal from "@/components/product/add-products-to-shop-modal.vue";
import AdvancedProductDetailModal from "@/components/product/advanced-product-detail-modal.vue";

export default {
  components: {
    AdvancedProductDetailModal,
    AddProductsToShopModal,
    ProductPriceDetailModal,
    Multiselect,
    PageHeader,
    CustomCardSubtitle,
    ShopProductLimitModal,
    EcatLightboxGallery,
    VueSlideBar,
    Switches,
    confirmModal,
    VirtualList
  },

  props: {
    shopId: {
      type: String
    }
  },

  data() {
    return {
      shopProductCard: ShopProductCard,
      loading: false,
      submitted: false,
      currentShopId: "",

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100, 500, 1000],
        filter: "",
        filterOn: [],
        sortBy: "name",
        sortDesc: false
      },

      inputPage: "",

      shopProducts: [],
      modifyTimestamps: [],
      isBusy: false,

      shop: {},
      shops: [],

      productsCount: 0,

      categories: new Map(),
      taxes: new Map(),
      // productStatuses: null,

      showModal: false,

      selectedProductImage: {
        visible: false,
        images: []
      },

      tableColumns: {
        visibleModal: false,

        // id: true,
        image: true,
        sku: true,
        name: true,
        categoryName: true,
        ean: true,
        profitMargin: true,
        priceHigh: true,
        priceWholesaleHigh: true,
        expect: true,
        totalWarehouseStock: true,
        warehouse: true,
        tax: true,
        deliveryTime: true,
        shippingCost: true
      },

      filtering: {
        auctionStatus: "",
        showProductsWithEAN: false,
        showProductsNotSentByError: false,
        ean: "",
        sku: "",
        name: "",
        priceMin: "",
        priceMax: "",
        warehouseId: "",
        categoryId: "",
        shopCategoryId: "",
        showProductsWithQuantityIsZero: false,
        status: "",
        currentMarketPlace: ""
      },

      form: {
        shopProducts: [],
        productIds: []
      },

      modals: {

        editProduct: {

          visibleModal: false,

          form: {
            profitLoweredByPercent: "1",
            priceIncreaseValue: "",
            priceIncreasePercent: "",

            categoryId: ""
          }

        },

        addToAllegro: {

          visible: false,

          form: {
            minPrice: 50.0,
            maxPrice: 50.0,

            onlyProductsInRange: false,
            allowListingInPaidCategories: false,
            // priceType: ""
          }

        },

        hideShopCategory: {
          visible: false,
          form: {
            categoryId: ""
          }
        }
      },

      orderByMap: new Map([
        ["productSku", "p.sku"],
        ["productName", "p.name"],
        ["productPriceHigh", "p.price_high"],
        ["productPriceWholesaleHigh", "p.price_wholesale_high"],
        ["productTotalWarehouseStock", "p.total_warehouse_stock"],
        ["tax", "p.tax_id"],
        ["deliveryTime", "p.delivery_time"]
      ]),

      warehouses: new Map(),
      productsOnMarketPlace: "WORDPRESS",
      deliveryUnits: new Map(),
      shippingCosts: new Map(),
      ALLEGRO_STATUSES: [ "ALL", "PREPARED_TO_SEND", "SEND_ERROR", "ACTIVE", "ENDED_BY_SYSTEM", "ENDED_BY_USER" ]
    };
  },

  validations: {
    selectedShop: {
      id: {required}
    },

    form: {
      shopCategoryId: {required},
    },

    modals: {

      // addToAllegro: {
      //   form: {
      //     priceType: {required}
      //   }
      // },

      hideShopCategory: {
        form: {
          categoryId: {required}
        }
      }

    }

  },

  watch: {
    "table.currentPage": {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue !== oldValue && oldValue !== undefined) {
          await this.loadProducts();
        }
      },
    },

    "table.perPage": {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue !== oldValue && oldValue !== undefined) {
          await this.loadProducts();
        }
      },
    }
  },

  methods: {

    getAllegroStatusBadge(status) {
      switch (status) {
        case "ALL":
          return "info-warning"
        case "PREPARED_TO_SEND":
          return "info-warning"
        case "SEND_ERROR":
          return "info-danger"
        case "ACTIVE":
          return "info-green"
        case "ENDED_BY_SYSTEM":
          return "info-danger"
        case "ENDED_BY_USER":
          return "info-danger"
      }
    },

    getAllegroStatusName(status) {
      switch (status) {
        case "ALL":
          return "Wszystkie statusy"
        case "PREPARED_TO_SEND":
          return "W trakcie wysyłania"
        case "SEND_ERROR":
          return "Błąd"
        case "ACTIVE":
          return "Aktywna"
        case "ENDED_BY_SYSTEM":
          return "Zakończona przez System"
        case "ENDED_BY_USER":
          return "Zakończona przez Użytkownika"
      }
    },

    sendOneProduct(product) {
      this.$refs.addProductsToShopModal.openModal(this.getSelectedShopId, [product.productId])
    },

    getMarketplaceLabel(text) {
      let words = text.split('_');
      let formattedWords = words.map(word => word.toLowerCase());
      formattedWords = formattedWords.map(word => word.charAt(0).toUpperCase() + word.slice(1));
      return formattedWords.join(' ');
    },

    openProductsWebsite() {
      window.location = this.shopId ? '/dashboard/admin/aderlo/products' : '/dashboard/products'
    },

    async selectOrUnselectAllegroMarketplace(allegroMarketplace) {
      await this.$store.dispatch("allegro/addOrRemoveMarketplace", allegroMarketplace)
    },

    async loadDeliveryUnits() {
      try {
        const {data} = await axios.get(`/delivery-unit`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.deliveryUnits = new Map(data.deliveryUnits.map((obj) => [obj.deliveryUnit, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    changeProductsOnMarketPlace(productsOnMarketPlace) {
      if (this.filtering.currentMarketPlace !== 'MARKETPLACE') {
        this.filtering.currentMarketPlace = productsOnMarketPlace === 'NONE' ? "" : "WORDPRESS"
      } else {
        this.filtering.currentMarketPlace = productsOnMarketPlace
      }

      if (productsOnMarketPlace === "MARKETPLACE") {
        this.filtering.currentMarketPlace = "ALLEGRO"
      }

      this.productsOnMarketPlace = productsOnMarketPlace
      if (this.filtering.currentMarketPlace === "WORDPRESS" || this.filtering.currentMarketPlace === 'MARKETPLACE') {
        this.setToFirstPageAndRefresh()
      }
    },

    changeCurrentMarketplace(currentMarketplace) {
      if (currentMarketplace === 'NONE') {
        this.productsOnMarketPlace = 'NONE'
      }

      this.filtering.currentMarketPlace = currentMarketplace
      this.setToFirstPageAndRefresh()
    },

    setCurrentShopId(shopId) {
      this.currentShopId = shopId
    },

    changeAuctionStatus(auctionStatus) {
      if (auctionStatus === "ALL") {
        auctionStatus = ""
      }

      this.filtering.auctionStatus = auctionStatus
      this.setToFirstPageAndRefresh()
    },

    getShopName(id) {
      const shop = this.getShop(id)
      return shop.name || ""
    },

    getShop(id) {
      const shops = this.$store.getters['shop/getShops']
      if (!shops) {
        return
      }

      for (const shop of shops) {
        if (shop.id === id) {
          return shop
        }
      }

      return {}
    },

    selectOrUnSelectProduct(shopProduct) {
      if (this.filtering.currentMarketPlace === "ALLEGRO") {
        if (this.form.productIds.includes(shopProduct.productId)) {
          this.form.productIds = this.form.productIds.filter(element => element !== shopProduct.productId)
          this.form.shopProducts = this.form.shopProducts.filter(element => element.productId !== shopProduct.productId);
        } else {
          this.form.productIds.push(shopProduct.productId)
          this.form.shopProducts = [...this.form.shopProducts, shopProduct];
        }
        return
      }

      if (this.form.productIds.includes(shopProduct.id)) {
        this.form.productIds = this.form.productIds.filter(element => element !== shopProduct.id)
        this.form.shopProducts = this.form.shopProducts.filter(element => element.id !== shopProduct.id);
      } else {
        this.form.productIds.push(shopProduct.id)
        this.form.shopProducts = [...this.form.shopProducts, shopProduct];
      }
    },

    async setToFirstPageAndRefresh() {
      this.table.currentPage = 1
      await this.loadProducts()
    },

    // async setProductStatusValue() {
    //   const multiSelect = await this.$store.getters['multiselect/getMultiselect']('17')
    //   if (!multiSelect) {
    //     return
    //   }
    //
    //   this.filtering.status = multiSelect.value
    // },

    // selectProductStatus(value) {
    //   this.$store.dispatch("multiselect/setMultiselect", {id: "17", value: value})
    //   this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    // },

    // async loadProductStatuses() {
    //   try {
    //     const {data} = await axios.get(`/shop/${this.getSelectedShopId}/product/statuses`, {
    //       data: {},
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Accept: 'application/json'
    //       }
    //     });
    //
    //     this.productStatuses = data
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },

    decrementMinPrice() {
      if (this.modals.addToAllegro.form.minPrice <= 1.0) {
        this.modals.addToAllegro.form.minPrice = 1.0;
        return;
      }

      this.modals.addToAllegro.form.minPrice -= 0.50;
    },

    decrementMaxPrice() {
      if (this.modals.addToAllegro.form.maxPrice <= 1.0) {
        this.modals.addToAllegro.form.maxPrice = 1.0;
        return;
      }

      this.modals.addToAllegro.form.maxPrice -= 0.50;
    },

    highlightRow(item) {
      if (!item) {
        return ''
      }

      return this.form.productIds.includes(item.productId) ? 'highlighted-product' : ''
    },

    selectAllProducts() {
      if (this.filtering.currentMarketPlace === "ALLEGRO") {
        const ids = this.shopProducts.map(product => product.productId);
        const newProductIds = ids.filter(id => !this.form.productIds.includes(id));

        if (newProductIds.length > 0) {
          this.form.productIds.push(...newProductIds);

          for (const shopProduct of this.shopProducts) {
            if (this.form.productIds.includes(shopProduct.productId)) {
              this.form.shopProducts.push(shopProduct)
            }
          }
        } else {
          this.form.productIds = this.form.productIds.filter(id => !ids.includes(id))
          this.form.shopProducts = this.form.shopProducts.filter(shopProduct => !ids.includes(shopProduct.productId))
        }

        return
      }

      const ids = this.shopProducts.map(product => product.id);
      const newProductIds = ids.filter(id => !this.form.productIds.includes(id));

      if (newProductIds.length > 0) {
        this.form.productIds.push(...newProductIds);

        for (const shopProduct of this.shopProducts) {
          if (this.form.productIds.includes(shopProduct.id)) {
            this.form.shopProducts.push(shopProduct)
          }
        }
      } else {
        this.form.productIds = this.form.productIds.filter(id => !ids.includes(id))
        this.form.shopProducts = this.form.shopProducts.filter(shopProduct => !ids.includes(shopProduct.id))
      }
    },

    isSameProductIds() {
      if (this.shopProducts.length === 0) {
        return false
      }

      const ids = this.shopProducts.map(product => product.id);
      return ids.every(id => this.form.productIds.includes(id))
    },

    async loadShippingCosts() {
      try {
        const productIds = [];
        for (let shopProduct of this.shopProducts) {
          productIds.push(shopProduct.productId);
        }

        const json = JSON.stringify({
          "productIds": productIds
        });

        const {data} = await axios.post(`/product/target-shipping-costs`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        if (data && data.data) {
          this.shippingCosts = new Map()

          data.data.forEach(item => {
            const productId = item.productId;
            const carrierData = {
              carrierId: item.carrierId,
              allegroCarrierId: item.allegroCarrierId,
              cost: item.cost,
              name: item.name
            };

            if (this.shippingCosts.has(productId)) {
              this.shippingCosts.get(productId).push(carrierData);
            } else {
              this.shippingCosts.set(productId, [carrierData]);
            }
          });
        }
      } catch (error) {
        console.log(error)
      }
    },

    getShippingCost(productId) {
      if (!this.shippingCosts || this.shippingCosts.size === 0) {
        return null;
      }

      return this.shippingCosts.get(productId) || null;
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('shop-products.title', {'name': this.shop.name}),
          active: true
        }
      ]
    },

    async loadWarehouses() {
      try {
        if (this.shopProducts.length === 0) {
          return;
        }

        const ids = [];
        for (let shopProduct of this.shopProducts) {
          ids.push(shopProduct.productWarehouseId);
        }

        const json = JSON.stringify({
          "ids": ids
        });

        const {data} = await axios.post(`/warehouse/fetch`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.warehouses = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        console.log(error)
      }
    },

    async loadProducts() {
      if (this.filtering.currentMarketPlace === "" || this.filtering.currentMarketPlace === 'MARKETPLACE') {
        this.shopProducts = []
        this.table.rows = 0
        this.table.totalRows = 0
        await this.loadAllProductsCount()
        return
      }

      this.loading = true;

      if (!this.getSelectedShopId) {
        return
      }

      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      let orderBy = {}
      if (this.table.sortBy) {
        const orderByElement = this.orderByMap.get(this.table.sortBy)
        if (orderByElement) {
          orderBy = JSON.stringify({
            [orderByElement]: this.table.sortDesc ? "DESC" : "ASC"
          })
        }
      }

      try {
        const {data} = await axios.get(`/shop/${this.getSelectedShopId}/product` + (this.filtering.currentMarketPlace === 'ALLEGRO' ? '/allegro' : ''), {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "page": page,
            "size": this.table.perPage,
            "auctionStatus": this.filtering.auctionStatus,
            "showProductsWithEan": this.filtering.showProductsWithEAN,
            "sku": this.filtering.sku,
            "ean": this.filtering.ean,
            "name": this.filtering.name,
            priceMin: this.filtering.priceMin,
            priceMax: this.filtering.priceMax,
            "warehouseId": this.filtering.warehouseId,
            "categoryId": this.filtering.categoryId,
            "shopCategoryId": this.filtering.shopCategoryId,
            "currentMarketPlace": this.filtering.currentMarketPlace === "NONE" ? "" : this.filtering.currentMarketPlace,
            "showProductsWithQuantityIsZero": this.filtering.showProductsWithQuantityIsZero,
            showProductsNotSentByError: this.filtering.showProductsNotSentByError,
            "filter": this.table.filter,
            "orderBy": orderBy
          }
        });

        this.shopProducts = data

        await Promise.all([
          this.loadProductsCount(),
          this.loadAllProductsCount(),
          this.loadWarehouses(),
          this.loadCategories(),
          this.loadTaxes(),
          this.loadShippingCosts(),
          this.loadProductModifyTimestamps()
        ]);

        this.loading = false
      } catch (error) {
        console.log(error)
        this.shopProducts = []
        this.loading = false
      }
    },

    async loadProductModifyTimestamps() {
      if (this.shopProducts.length === 0) {
        return;
      }

      const ids = [];
      for (let shopProduct of this.shopProducts) {
        ids.push(shopProduct.productId);
      }

      try {
        const json = {
          productIds: ids
        }

        const {data} = await axios.post(`/catalog/shop/${this.getSelectedShopId}/modify-timestamps`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.modifyTimestamps = data.data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadProductsCount() {
      try {
        const {data} = await axios.get(`/shop/${this.getSelectedShopId}/product/count` + (this.filtering.currentMarketPlace === 'ALLEGRO' ? '/allegro' : ''), {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "showProductsWithEan": this.filtering.showProductsWithEAN,
            "auctionStatus": this.filtering.auctionStatus,
            "sku": this.filtering.sku,
            "ean": this.filtering.ean,
            "name": this.filtering.name,
            priceMin: this.filtering.priceMin,
            priceMax: this.filtering.priceMax,
            "warehouseId": this.filtering.warehouseId,
            "categoryId": this.filtering.categoryId,
            "shopCategoryId": this.filtering.shopCategoryId,
            "currentMarketPlace": this.filtering.currentMarketPlace === "NONE" ? "" : this.filtering.currentMarketPlace,
            "showProductsWithQuantityIsZero": this.filtering.showProductsWithQuantityIsZero,
            showProductsNotSentByError: this.filtering.showProductsNotSentByError,
            "filter": this.table.filter
          }
        });

        this.table.totalRows = data
        this.table.rows = data
      } catch (error) {
        console.log(error)
        this.table.totalRows = 0
        this.table.rows = 0
      }
    },

    async loadAllProductsCount() {
      try {
        const {data} = await axios.get(`/shop/${this.getSelectedShopId}/product/count/all`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.productsCount = data
      } catch (error) {
        console.log(error)
      }
    },

    async loadCategories() {
      if (this.shopProducts.length === 0) {
        return;
      }

      const result = await axios.get(`/shop/${this.getSelectedShopId}/category/list`, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.categories = new Map(result.data.map((obj) => [obj.id, {"id:": obj.id, "id": obj.id, "name": obj.name}]));
    },

    async loadTaxes() {
      if (this.shopProducts.length === 0) {
        return;
      }

      const ids = [];
      for (let shopProduct of this.shopProducts) {
        ids.push(shopProduct.productTaxId);
      }

      const json = JSON.stringify({
        "ids": ids
      });

      const result = await axios.post(`/tax/list-by-ids`, json, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.taxes = new Map(result.data.map((obj) => [obj.id, obj]));
    },

    getCategoryName(categoryId) {
      const category = this.categories.get(categoryId);
      if (!category) {
        return "";
      }

      return category.name;
    },

    getTaxRate(taxId) {
      const tax = this.taxes.get(taxId);
      if (!tax) {
        return "";
      }

      return tax.rate;
    },

    getTime(value) {
      const humanizeDuration = require("humanize-duration");
      return humanizeDuration(value, {language: this.$store.getters["translation/getCurrentLocaleOrFallback"]});
    },

    asArray(array) {
      try {
        return JSON.parse(array);
      } catch (error) {
        return []
      }
    },

    async loadShop(id) {
      const {data} = await axios.get(`/shop/${id}`, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.shop = data
    },

    selectProductImages(images) {
      this.selectedProductImage.images = images
      this.selectedProductImage.visible = true
    },

    hideSelectedProductImage() {
      this.selectedProductImage.images = []
      this.selectedProductImage.visible = false
    },

    clearFilters() {
      this.filtering.showProductsWithEAN = false
      this.filtering.showProductsWithQuantityIsZero = false
      this.filtering.sku = ""
      this.filtering.ean = ""
      this.filtering.name = ""
      this.filtering.priceMin = ""
      this.filtering.priceMax = ""
      this.filtering.warehouseId = ""
      this.filtering.categoryId = ""
      this.filtering.shopCategoryId = ""
      this.filtering.status = ""
    },

    resetFilter(filter) {
      this.filtering[filter.field] = filter.isBool ? false : "";
      this.setToFirstPageAndRefresh()
    },

    getFilters() {
      const filters = []
      if (this.filtering.name) {
        filters.push(this.createFilter("Nazwa produktu", "badge-custom-yellow", "name"))
      }

      if (this.filtering.ean) {
        filters.push(this.createFilter("EAN", "badge-custom-purple", "ean"))
      }

      if (this.filtering.sku) {
        filters.push(this.createFilter("SKU", "badge-soft-success", "sku"))
      }

      if (this.filtering.warehouseId) {
        filters.push(this.createFilter("Hurtownia", "badge-soft-info", "warehouseId"))
      }

      if (this.filtering.categoryId) {
        filters.push(this.createFilter("Kategoria", "badge-soft-warning", "categoryId"))
      }

      if (this.filtering.shopCategoryId) {
        filters.push(this.createFilter("Kategoria sklepu", "badge-custom-pink", "shopCategoryId"))
      }

      if (this.filtering.status) {
        filters.push(this.createFilter("Status", "badge-custom-sea", "status"))
      }

      if (this.filtering.showProductsWithQuantityIsZero) {
        filters.push(this.createFilter("Produkty ze stanem zerowym", "badge-custom-sea", "showProductsWithQuantityIsZero", true))
      }

      if (this.filtering.showProductsWithEAN) {
        filters.push(this.createFilter("Produkty posiadające EAN", "badge-custom-sapphire", "showProductsWithEAN", true))
      }

      if (filters.length === 0) {
        filters.push(this.createFilter("Brak", "badge-soft-danger", null))
      }

      return filters
    },

    createFilter(name, badge, field, isBool = false) {
      return {
        name: name,
        badge: badge,
        field: field,
        isBool: isBool
      }
    },

    getProductStatusText(status) {
      switch (status) {
        case "NOT_SENT":
          return "Do wysłania"
        case "SENT":
          return "Wysłany"
        case "TO_UPDATE":
          return "Do aktualizacji"
        case "TO_RESET_QUANTITY":
          return "Do wyzerowania"
        case "DELETED":
          return "Ukryty"
        case "TO_DELETE":
          return "Do Usunięcia"
        case "UNAVAILABLE":
          return "Niedostępny"
        case "ERROR":
          return "Z błędem"
        case "ONLY_IN_ALLEGRO":
          return "Na Allegro"
      }

      return ""
    },

    getProductStatusBadge(product) {
      switch (product.status) {
        case "NOT_SENT":
          return "badge-soft-primary"
        case "SENT":
          return "badge-soft-success"
        case "TO_UPDATE":
          return "badge-soft-warning"
        case "TO_RESET_QUANTITY":
          return "badge-soft-secondary"
        case "TO_DELETE":
          return "badge-soft-info"
        case "DELETED":
          return "badge-soft-danger"
        case "ONLY_IN_ALLEGRO":
          return "badge-soft-info"
        case "UNAVAILABLE":
          return "badge-soft-secondary"
      }

      return ""
    },

    setProductsStatus(status, callback) {
      const json = JSON.stringify({
        "productIds": this.form.productIds,
        "status": status
      });

      axios.post(`/shop/${this.getSelectedShopId}/product/change-status`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(async () => {
        callback()
        this.clearProductIds()
        await this.loadProducts()
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    deleteProducts() {
      if (!this.checkSelectedProductsIsNotEmpty()) {
        return
      }

      swalHelper.yesOrNoCustomizable(() => {
        if (this.filtering.currentMarketPlace === "ALLEGRO") {
          this.deleteProductAuctions()
          return
        }

        const json = {
          shopProductIds: this.form.productIds
        }

        axios.post(`/shop/${this.getSelectedShopId}/product/delete`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }).then(async () => {
          await Swal.fire("Sukces!", `Pomyślnie usunięto ${this.form.productIds.length} produktów`, "success");
          this.clearProductIds()
          await this.loadProducts()
        }).catch((error) => {
          errorCatcher.catchErrors(error);
        })
      }, "warning", "Czy na pewno?", "Produkt zostanie usunięty w sklepie", "Usuń", "Anuluj", "btn btn-danger", "btn btn-secondary")
    },

    deleteProductAuctions() {
      const json = {
        shopId: this.getSelectedShopId,
        productIds: this.form.productIds,
      }

      axios.post(`/allegro/auction/end-user-auctions`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(async () => {
        await Swal.fire("Sukces!", `Pomyślnie wysłano ${this.form.productIds.length} aukcji do kolejki usuwania`, "success");
        this.clearProductIds()
        await this.loadProducts()
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    checkSelectedProductsIsNotEmpty() {
      if (this.form.productIds.length === 0) {
        Swal.fire(this.$t('message.error'), "Najpierw zaznacz przynajmniej jeden produkt!", "error");
        return false
      }

      return true
    },

    openEditProductsModal() {
      if (!this.checkSelectedProductsIsNotEmpty()) {
        return
      }

      this.modals.editProduct.visibleModal = true
    },

    hideEditProductModal() {
      this.modals.editProduct.visibleModal = false
      this.modals.editProduct.profitLoweredByPercent = "1"
      this.modals.editProduct.priceIncreaseValue = ""
      this.modals.editProduct.priceIncreasePercent = ""
      this.modals.editProduct.categoryId = ""
    },

    refreshSlidebar() {
      this.$refs.slidebar.refresh()
    },

    editProducts() {
      swalHelper.yesOrNoCustomizable(() => {
        const json = JSON.stringify({
          "productIds": this.form.productIds,
          "shopCategoryId": this.modals.editProduct.form.categoryId ? this.modals.editProduct.form.categoryId : "",
          "profitLoweredByPercent": this.modals.editProduct.form.profitLoweredByPercent ? Number(this.modals.editProduct.form.profitLoweredByPercent) : -1,
          "priceIncreaseValue": this.modals.editProduct.form.priceIncreaseValue ? Number(this.modals.editProduct.form.priceIncreaseValue) : -1,
          "priceIncreasePercent": this.modals.editProduct.form.priceIncreasePercent ? Number(this.modals.editProduct.form.priceIncreasePercent) : -1,
        });

        axios.post(`/shop/${this.getSelectedShopId}/product/edit-products`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
        }).then(async () => {
          this.hideEditProductModal()

          await Swal.fire("Sukces!", `Pomyślnie edytowano ${this.form.productIds.length} produktów`, "success");

          this.clearProductIds()
          await this.loadProducts()
        }).catch((error) => {
          errorCatcher.catchErrors(error);
        })
      }, "warning", "Czy na pewno?", "Zaznaczone produkty zostaną edytowane", "Jestem pewien!", "Anuluj", "btn btn-danger", "btn btn-secondary")
    },

    hideProducts() {
      if (!this.checkSelectedProductsIsNotEmpty()) {
        return
      }

      swalHelper.yesOrNoCustomizable(() => {
        this.setProductsStatus("DELETED", () => {
          Swal.fire("Sukces!", `Pomyślnie ukryto ${this.form.productIds.length} produktów`, "success");
        })
      }, "warning", "Czy na pewno?", "Produkt zostanie ukryty w sklepie", "Ukryj", "Anuluj", "btn btn-danger", "btn btn-secondary")
    },

    restoreProducts() {
      if (!this.checkSelectedProductsIsNotEmpty()) {
        return
      }

      swalHelper.yesOrNoCustomizable(() => {
        this.setProductsStatus("NOT_SENT", () => {
          Swal.fire("Sukces!", `Pomyślnie przywrócono ${this.form.productIds.length} produktów`, "success");
        })
      }, "warning", "Czy na pewno?", "Produkt zostanie przywrócony do sklepu", "Przywróć", "Anuluj", "btn btn-danger", "btn btn-secondary")
    },

    openModalAddToAllegro() {
      if (!this.checkSelectedProductsIsNotEmpty()) {
        return
      }

      if (!this.$store.getters['allegro/getCurrentAllegroAccountId']) {
        Swal.fire(this.$t('message.error'), "Wybierz aktywne konto Allegro", "error");
        return
      }

      this.modals.addToAllegro.visible = true
    },

    hideModalAddToAllegro() {
      this.modals.addToAllegro.visible = false
    },

    async addProductsToAllegro() {
      this.submitted = true

      // this.$v.modals.addToAllegro.$touch();
      // if (this.$v.modals.addToAllegro.$invalid) {
      //   return;
      // }

      const json = JSON.stringify({
        allegroAccountId: this.$store.getters['allegro/getCurrentAllegroAccountId'],
        targetType: "ALLEGRO",
        priceIncreasePercent: 0,
        priceIncreaseValue: 0,
        profitLoweredByPercent: 0,
        productIds: this.form.productIds,
        additionalMarketplaces: this.$store.getters['allegro/getSelectedMarketplaces'],
        priceMin: this.modals.addToAllegro.form.onlyProductsInRange ? null : this.modals.addToAllegro.form.minPrice,
        priceMax: this.modals.addToAllegro.form.onlyProductsInRange ? null : this.modals.addToAllegro.form.maxPrice,
        allowListingInPaidCategories: this.modals.addToAllegro.form.allowListingInPaidCategories,
      });

      const count = this.form.productIds.length

      this.clearProductIds()

      try {
        await axios.post(`/shop/${this.getSelectedShopId}/product/queue`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
        })

        toastHelper.success(`Dodano: ${count} produktów do kolejki wysyłania na Allegro`)
      } catch (error) {
        const ecatError = errorCatcher.getError(error)
        if (ecatError) {
          const message = errorCatcher.catchErrors(error)
          if (ecatError.type === "ShopProductErrors" && ecatError.code === 8) {
            Swal.fire("Błąd!", message, "error").then(() => this.$emit("open-shop-product-limit-modal", this.form.shopId))
          } else if (ecatError.type === "AllegroAuctionCronErrors" && ecatError.code === 18) {
            toastHelper.success(`Dodano: 0 produktów na Allegro`)
            toastHelper.success("Wszystkie zaznaczone produkty są już wysłane do Allegro (z takim samym kodem EAN)")
          } else {
            errorCatcher.catchErrors(error)
          }
        }
      }

      this.hideModalAddToAllegro()
    },

    deleteProductsWhichIsNotOnAllegro() {
      swalHelper.yesOrNoCustomizable(() => {
        axios.post(`/shop/${this.getSelectedShopId}/product/delete-products-without-allegro-auction`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        }).then(({ data }) => {
          Swal.fire("Sukces!", `${data.count} produktów zostało usuniętych!`, "success").then(async () => {
            await this.loadProducts()
          });
        }).catch((error) => {
          errorCatcher.catchErrors(error);
        })
      }, "warning", "Czy na pewno?", "Produkty, które nie znajdują się na Allegro zostaną usunięte", "Usuń", "Anuluj", "btn btn-danger", "btn btn-secondary")
    },

    openModalHideShopCategory() {
      this.modals.hideShopCategory.visible = true
    },

    hideModalHideShopCategory() {
      this.modals.hideShopCategory.visible = false
      this.modals.hideShopCategory.form.categoryId = ""
    },

    hideShopCategory() {
      this.submitted = true

      this.$v.modals.hideShopCategory.$touch();
      if (this.$v.modals.hideShopCategory.$invalid) {
        return;
      }

      swalHelper.yesOrNoCustomizable(() => {
        axios.post(`/shop/${this.getSelectedShopId}/product/hide-category/${this.modals.hideShopCategory.form.categoryId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        }).then((result) => {
          Swal.fire("Sukces!", `${result.data} produktów zostało ukrytych!`, "success").then(async () => {
            this.hideModalHideShopCategory()
            await this.loadProducts()
          });
        }).catch((error) => {
          errorCatcher.catchErrors(error);
        })
      }, "warning", "Czy na pewno?", "Kategoria zostanie ukryta w sklepie", "Ukryj", "Anuluj", "btn btn-danger", "btn btn-secondary")
    },

    clearProductIds() {
      this.form.productIds = []
      this.form.shopProducts = []
    },

    async changeShop(shopId) {
      this.currentShopId = shopId
      await this.$store.dispatch("allegro/setCurrentAllegroAccountId", "")
      await this.$store.dispatch("shop/setSelectedShopId", shopId)
      await this.loadShop(this.getSelectedShopId)

      if (this.$store.getters['engine/getEngineName'](this.shop.engineId) === 'engine.wordpress' && !this.$store.getters['shop/isPairedShop'](this.shop.id)) {
        this.$router.push('/dashboard/wordpress-not-activated').then(() => {
          // Swal.fire("", this.$t('user-shops.wordpress-is-not-activated'), "error");
        }).catch((error) => {
          if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
            console.log(error)
          }
        })
        return
      }

      this.setToFirstPageAndRefresh()
    },

    async changeAllegroAccount(allegroAccountId) {
      await this.$store.dispatch("allegro/setCurrentAllegroAccountId", allegroAccountId)
    },

    async clearAllegroAccount() {
      const json = {
        allegroAccountId: this.$store.getters['allegro/getCurrentAllegroAccountId']
      }

      try {
        await axios.post(`/allegro/clear-account`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
        })

        this.$refs.confirmModal.hideModal();
        toastHelper.success(`Pomyślnie dodano do kolejki czyszczenia kont Allegro`)
        this.setToFirstPageAndRefresh()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    openClearAccountModal() {
      if (!this.$store.getters['allegro/getCurrentAllegroAccountId']) {
        Swal.fire(this.$t('message.error'), "Wybierz aktywne konto Allegro", "error");
        return
      }

      this.$refs.confirmModal.openModal('Wyczyść całe konto Allegro', '', 'Potwierdź', () => this.clearAllegroAccount())
    },

    loadParams() {
      try {
        const params = routerHelper.params();
        const productsOnMarketPlace = params.productsOnMarketPlace

        if (productsOnMarketPlace) {
          this.productsOnMarketPlace = "MARKETPLACE"
          this.filtering.currentMarketPlace = "ALLEGRO"
        } else {
          this.filtering.currentMarketPlace = "WORDPRESS"
        }
      } catch (error) {
        // ignore
      }
    }

  },

  computed: {
    Swal() {
      return Swal
    },
    productHelper() {
      return productHelper
    },

    paginationHelper() {
      return paginationHelper
    },

    getSelectedShopId() {
      if (this.currentShopId) {
        return this.currentShopId
      }

      try {
        const params = routerHelper.params();
        const shopId = params.shopId

        if (shopId) {
          if (!this.currentShopId) {
            this.setCurrentShopId(shopId)
          }

          return shopId
        }
      } catch (error) {
        // ignore
      }

      if (this.shopId) {
        return this.shopId
      }

      return this.$store ? this.$store.state.shop.shopId : '';
    }
  },

  async created() {
    try {
      this.loadParams()
      await this.loadDeliveryUnits()
      // await this.setProductStatusValue()
      await this.loadShop(this.getSelectedShopId)
      // await this.loadProductStatuses()
    } catch (error) {
      console.log(error)
    }
  },

  async mounted() {
    setTimeout(async () => await this.loadProducts(), 1250)
  }

}
</script>

<template>
  <div>
    <PageHeader :title="$t('shop-products.title', { 'name': shop.name })" :items="getItems()"/>
    <shop-product-limit-modal ref="shopProductLimitRef"/>
    <confirm-modal ref="confirmModal"/>

    <div class="row">
      <div class="col-xl-6 col-md-12 mb-3">
        <div class="card-body px-0">
          <div class="col-sm-12 shop-header rounded">
            <div class="row">
              <div class="col-xl-1 col-md-12 header-card-icon-bg d-flex align-items-center justify-content-center">
                <img src="@/assets/images/products.svg" alt="Products Icons" class="my-2 my-xl-0">
              </div>

              <div
                  class="col-xl-6 col-md-12 p-3 d-flex d-xl-block align-items-center justify-content-center flex-column">
                <div class="w-100 color-primary text-center text-xl-left">
                  Produkty na sklepie:&nbsp;<span class="text-primary font-weight-bold">
                  {{ shop ? shop.name : '' }}
                </span>
                </div>

                <div
                    class="d-flex flex-column align-items-center justify-content-center d-xl-flex flex-xl-row align-items-xl-center w-100">
                  <span class="font-weight-bold font-size-34 color-secondary">{{ productsCount }}/{{
                      shop.productLimit
                    }}
                  </span>

                  <b-button variant="primary" class="w-md px-4 mx-2 w-100"
                            @click="$refs.shopProductLimitRef.openModal(shop.id)">
                    <i class="mdi mdi-plus mr-2"></i>
                    Zwiększ limit
                  </b-button>
                </div>
              </div>

              <div class="col-xl-5 p-3">
                <div v-if="$store.getters['shop/getShops'] && $store.getters['shop/getShopsIds']">
                  <label>Aktywny sklep</label>
                  <multiselect
                      class="shop-selector"
                      :value="getSelectedShopId"
                      @select="value => changeShop(value)"
                      :custom-label="value => getShopName(value)"
                      :options="$store.getters['shop/getShopsIds']"
                      placeholder="Wybierz aktywny sklep"
                      :show-labels="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-12">
            <div class="row nopadding card-buttons">
              <div class="col-xl card-button" @click="openProductsWebsite">
                <i class="fas fa-boxes mr-2"></i>
                <div class="text">
                  Wszystkie dostępne produkty
                </div>
              </div>
              <div class="col-xl card-button" @click="changeProductsOnMarketPlace('WORDPRESS')"
                   :class="productsOnMarketPlace === 'WORDPRESS' ? 'active' : ''">
                <i class="fas fa-store-alt mr-2"></i>
                <div class="text">
                  Produkty dodane do sklepu internetowego
                </div>
              </div>
              <div class="col-xl card-button" @click="changeProductsOnMarketPlace('MARKETPLACE')"
                   :class="productsOnMarketPlace === 'MARKETPLACE' ? 'active' : ''">
                <i class="fas fa-shopping-basket mr-2"></i>
                <div class="text">
                  Produkty dodane do Marketplace
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="col-lg-12">
              <div class="card-body px-0">
                <div class="col-sm-12 shop-header rounded">
                  <div class="row">
                    <div class="col-xl-2 header-card-icon-bg d-flex align-items-center justify-content-center">
                      <template v-if="filtering.currentMarketPlace === 'ALLEGRO'">
                        <img src="@/assets/images/brands/allegro.svg" alt="Brand Icon" class="w-xl p-3">
                      </template>
                      <template v-else-if="filtering.currentMarketPlace === 'WORDPRESS' && shopId">
                        <img src="@/assets/images/brands/aderlo.svg" alt="Brand Icon" class="w-xl p-3">
                      </template>
                      <template v-else-if="filtering.currentMarketPlace === 'WORDPRESS'">
                        <img src="@/assets/images/brands/wordpress.svg" alt="Brand Icon" class="w-xl p-3">
                      </template>
                    </div>

                    <div class="col-xl-5 px-3 py-2">
                      <div
                          v-if="filtering.currentMarketPlace === 'ALLEGRO' && $store.getters['allegro/getCurrentAllegroAccountId']">
                        <!--                                    <div class="w-100 color-primary">-->
                        <!--                                      Produkty dodane do :&nbsp;<span class="text-primary font-weight-bold">Nazwa Konta Allegro</span>-->
                        <!--                                    </div>-->

                        <!--                                    <div class="d-flex flex-row align-items-center"><span class="font-weight-bold font-size-34 color-secondary">265</span>-->
                        <!--                                      <span class="ml-2 py-2 font-size-12 badge badge-soft-success">1012 Aktywnych aukcji</span>-->
                        <!--                                    </div>-->
                      </div>
                    </div>

                    <div class="col-xl-2 px-3 py-2">
                      <label>Marketplace:</label>
                      <multiselect
                          @select="changeCurrentMarketplace"
                          :value="filtering.currentMarketPlace"
                          :custom-label="value => getMarketplaceLabel(value)"
                          :options="productsOnMarketPlace === 'MARKETPLACE' ? ['ALLEGRO'] : ['WORDPRESS']"
                          placeholder="Wybierz marketplace"
                          :show-labels="false"
                      />
                    </div>

                    <div class="col-xl-3 px-3 py-2" v-if="filtering.currentMarketPlace === 'ALLEGRO'">
                      <ecat-multiselect
                          :set-value="$store.getters['allegro/getCurrentAllegroAccountId']"
                          @change="value => changeAllegroAccount(value)"
                          label="Aktywne konto Allegro"
                          placeholder="Wybierz aktywne konto allegro"
                          :fetch-one-url="`/allegro/${getSelectedShopId}`"
                          :load-url="`/allegro/list/pagination/${getSelectedShopId}`"
                          :query-url="`/allegro/by-name/${getSelectedShopId}`"
                          save-id="id"
                          view-id="name"
                          param="name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <custom-card-subtitle title="Filtrowanie"/>

              <div class="col-lg-12 row mb-3">
                <div class="col-lg-2">
                  <ecat-input
                      label="Nazwa produktu"
                      v-model="filtering.name"
                      type="text"
                      @should-reload="setToFirstPageAndRefresh"
                  />
                </div>

                <div class="col-lg-2">
                  <ecat-input
                      label="EAN"
                      v-model="filtering.ean"
                      type="number"
                      @should-reload="setToFirstPageAndRefresh"
                  />
                </div>

                <div class="col-lg-2">
                  <ecat-input
                      label="SKU"
                      v-model="filtering.sku"
                      type="text"
                      @should-reload="setToFirstPageAndRefresh"
                  />
                </div>

                <div class="col-lg-2">
                  <ecat-input
                      id="price-min"
                      label="Cena minimalna"
                      v-model="filtering.priceMin"
                      type="number"
                      @should-reload="setToFirstPageAndRefresh"
                  />
                </div>

                <div class="col-lg-2">
                  <ecat-input
                      id="price-max"
                      label="Cena maksymalna"
                      v-model="filtering.priceMax"
                      type="number"
                      @should-reload="setToFirstPageAndRefresh"
                  />
                </div>
              </div>

              <div class="col-lg-12 row mb-3">
                <div class="col-lg-2 col-12">
                  <ecat-multiselect
                      multi-select-id="6"
                      @change="value => filtering.warehouseId = value"
                      @select="setToFirstPageAndRefresh"
                      :set-value="filtering.warehouseId"
                      save-id="id"
                      view-id="name"
                      label="Hurtownia"
                      placeholder="Wszystkie hurtownie"
                      fetch-one-url="/warehouse"
                      load-url="/warehouse/list/pagination"
                      query-url="/warehouse/by-name"
                      param="name"
                      :can-unselect="true"
                      :custom-params="{'withProducts': true, 'shopId': getSelectedShopId}"
                  />
                </div>

                <div class="col-lg-2 col-12">
                  <ecat-multiselect
                      multi-select-id="7"
                      @change="value => filtering.categoryId = value"
                      @select="setToFirstPageAndRefresh"
                      :set-value="filtering.categoryId"
                      view-id="name"
                      save-id="id"
                      :can-unselect="true"
                      label="Kategoria"
                      placeholder="Wszystkie kategorie"
                      fetch-one-url="/category"
                      load-url="/category/list/pagination"
                      query-url="/category/by-name"
                      param="name"
                      :custom-params="{'withProducts': true}"
                  />
                </div>

                <div class="col-lg-2 col-12">
                  <ecat-multiselect
                      multi-select-id="8"
                      @change="value => filtering.shopCategoryId = value"
                      @select="setToFirstPageAndRefresh"
                      :set-value="filtering.shopCategoryId"
                      view-id="name"
                      save-id="id"
                      :can-unselect="true"
                      label="Kategoria sklepu"
                      placeholder="Wszystkie kategorie"
                      :fetch-one-url="`/shop/${this.getSelectedShopId}/category`"
                      :load-url="`/shop/${this.getSelectedShopId}/category/list/pagination`"
                      :query-url="`/shop/${this.getSelectedShopId}/category/by/name`"
                      param="name"
                  />
                </div>

                <div class="col-lg-2 col-12" v-if="filtering.currentMarketPlace === 'ALLEGRO'">
                  <label>Status aukcji:</label>
                  <multiselect
                      @select="changeAuctionStatus"
                      :value="filtering.auctionStatus"
                      :custom-label="value => getMarketplaceLabel(value)"
                      :options="ALLEGRO_STATUSES"
                      placeholder="Wszystkie statusy"
                      :show-labels="false">
                    <template slot="singleLabel" slot-scope="{ option }">
                    <span class="badge" :class="getAllegroStatusBadge(option)">
                      {{ getAllegroStatusName(option) }}
                    </span>
                    </template>

                    <template slot="option" slot-scope="{ option }">
                    <span class="badge" :class="getAllegroStatusBadge(option)">
                      {{ getAllegroStatusName(option) }}
                    </span>
                    </template>
                  </multiselect>
                </div>

                <!--              <div class="col-lg-2 col-12">-->
                <!--                <label>Status</label>-->
                <!--                <vue-multiselect-->
                <!--                    v-model="filtering.status"-->
                <!--                    @select="value => selectProductStatus(value)"-->
                <!--                    @remove="selectProductStatus('')"-->
                <!--                    :options="productStatuses ? productStatuses : []"-->
                <!--                    :custom-label="value => this.getProductStatusText(value)"-->
                <!--                    placeholder="Wszystkie stany produktu"-->
                <!--                    :show-labels="false"-->
                <!--                />-->
                <!--              </div>-->
              </div>

              <div class="col-lg-12 row mb-3">
                <div class="col-lg-12 d-flex flex-sm-row">
                  <div class="d-flex flex-sm-column mr-2">
                    <label class="font-size-13">
                      Produkty ze stanem zerowym: {{ filtering.showProductsWithQuantityIsZero ? "Tak" : "Nie" }}
                    </label>

                    <switches :emit-on-mount="false" @input="setToFirstPageAndRefresh()"
                              v-model="filtering.showProductsWithQuantityIsZero"
                              class="my-0" color="primary"/>
                  </div>

                  <div class="d-flex flex-sm-column mr-2">
                    <label class="font-size-13">
                      Produkty posiadające EAN: {{ filtering.showProductsWithEAN ? "Tak" : "Nie" }}
                    </label>

                    <switches :emit-on-mount="false" @input="setToFirstPageAndRefresh()"
                              v-model="filtering.showProductsWithEAN" class="my-0" color="primary"/>
                  </div>

                  <div class="d-flex flex-sm-column">
                    <label class="font-size-13">
                      Produkty nie wysłane na allegro ze względu na błąd:
                      {{ filtering.showProductsNotSentByError ? "Tak" : "Nie" }}
                    </label>

                    <switches :emit-on-mount="false" @input="setToFirstPageAndRefresh()"
                              v-model="filtering.showProductsNotSentByError" class="my-0" color="primary"/>
                  </div>
                </div>
              </div>

              <!--              <div class="button-items mb-2">-->
              <!--                <a @click="tableColumns.visibleModal = true" class="btn btn-primary">-->
              <!--                  Dostosuj kolumny-->
              <!--                </a>-->

              <div class="col-lg-12 row mb-3 pl-4">
                <b-button variant="primary" class="w-xxl mr-2" @click="loadProducts">Szukaj</b-button>
                <b-button variant="outline-primary" class="w-xxl mr-2" @click="clearFilters">Wyczyść filtry</b-button>

                <div class="d-flex flex-column">
                  <div class="font-size-12 font-weight-bold">
                    Aktualne Filtry:
                  </div>
                  <div class="badges">
                  <span v-for="(filter, index) in getFilters()" class="badge font-size-11 mr-1" :class="filter.badge"
                        :key="index">
                    <span v-if="filter.field" @click="resetFilter(filter)" class="clickable-element text-danger">
                      <i class="fa fa-times"/>
                    </span>

                    {{ filter.name }}
                  </span>
                  </div>
                </div>

              </div>

            </div>

            <div class="row" v-if="filtering.currentMarketPlace !== ''">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-lg-12 row">
                <div class="col-lg-12">

                  <div class="button-items mb-3">
                    <template v-if="filtering.currentMarketPlace === 'ALLEGRO'">
                      <!--                    <b-button variant="danger" @click="deleteProductsWhichIsNotOnAllegro">Usuń produkty, które nie-->
                      <!--                      znajdują się na Allegro-->
                      <!--                    </b-button>-->

                      <b-button @click="openClearAccountModal" variant="outline-danger">Wyczyść całe konto Allegro
                      </b-button>
                    </template>

                    <template v-if="filtering.currentMarketPlace === 'WORDPRESS'">
                      <b-button variant="danger" @click="openModalHideShopCategory">Ukryj całą kategorię sklepu
                      </b-button>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <!--              <div class="col-lg-12 row mb-3 pl-4">-->
            <!--                <b-button variant="danger" class="px-4 mr-2">Zakończ wszystkie aukcje</b-button>-->
            <!--                <b-button variant="danger" class="px-4 mr-2">Usuń wszystkie aukcje</b-button>-->
            <!--                <b-button variant="danger" class="px-4 mr-2">Usuń akucje z błędami</b-button>-->
            <!--                <b-button variant="danger" class="px-4 mr-2">Usuń aukcje niegotowe do wysyłki</b-button>-->
            <!--                <b-button variant="primary" class="px-4 mr-2">Usuń szkice</b-button>-->
            <!--              </div>-->

            <!--              <div class="col-lg-12 row mb-3 pl-4">-->
            <!--                <b-button variant="warning" class="px-4 mr-2">Ponów aukcje z błędami</b-button>-->
            <!--                <b-button variant="warning" class="px-4 mr-2">Wymuś sprawdzenie niegotowych produktów</b-button>-->
            <!--              </div>-->
            <div class="row">
              <custom-card-subtitle title="Akcje masowe (wymagają zaznaczenia wieszy w tabeli)"/>

              <div class="col-lg-12 row">
                <div class="col-lg-12">

                  <div class="button-items mb-3">
                    <!--                  <a @click="openEditProductsModal" class="btn btn-primary">Edytuj wybrane produkty</a>-->
                    <a @click="deleteProducts" class="btn btn-danger">Usuń wybrane produkty</a>
                    <a v-if="filtering.currentMarketPlace === 'WORDPRESS'" @click="hideProducts" class="btn btn-danger">Ukryj
                      produkty w sklepie</a>
                    <a v-if="filtering.currentMarketPlace === 'WORDPRESS'" @click="restoreProducts"
                       class="btn btn-danger">Przywróć produkty do sklepu</a>
                    <a @click="openModalAddToAllegro" class="btn btn-warning">Wystaw na allegro</a>
                  </div>
                </div>
              </div>

              <!--              <div class="col-lg-12 row mb-3 pl-4">-->
              <!--                <b-button variant="success" class="px-4 mr-2">-->
              <!--                  <i class="mdi mdi-plus mr-2"></i>-->
              <!--                  Dodaj do sklepu internetowego-->
              <!--                </b-button>-->
              <!--              </div>-->

              <!--              <div class="col-lg-12 row mb-3 pl-4">-->
              <!--                <b-button variant="primary" class="px-4 mr-2">Uzupełnij parametry</b-button>-->
              <!--                <b-button variant="primary" class="px-4 mr-2">Zwiększ/zmniejsz cenę</b-button>-->

              <!--                <b-button variant="warning" class="px-4 mr-2">Ponów zaznaczone aukcje</b-button>-->

              <!--                <b-button variant="danger" class="px-4 mr-2">Ustaw zaznaczone jako błędne</b-button>-->
              <!--                <b-button variant="danger" class="px-4 mr-2">Zakończ zaznaczone aukcje</b-button>-->
              <!--                <b-button variant="danger" class="px-4 mr-2">Usuń wybrane aukcje</b-button>-->
              <!--              </div>-->
            </div>


            <ecat-lightbox-gallery :visible="selectedProductImage.visible" :images="selectedProductImage.images"
                                   @hide="hideSelectedProductImage"/>

            <div class="py-2">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.show') }}&nbsp;
                      <b-form-select v-model="table.perPage" size="sm"
                                     :options="table.pageOptions" @loaded="setToFirstPageAndRefresh"></b-form-select>&nbsp;{{
                        $t('table.entries')
                      }}
                    </label>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.search') }}
                      <b-form-input v-model="table.filter" class="form-control form-control-sm ml-2" type="search"
                                    @keyup.enter="setToFirstPageAndRefresh"
                                    @input="setToFirstPageAndRefresh"></b-form-input>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm-12 col-md-6">
                  <b-button
                      class="btn-select-all mr-2 d-inline-flex align-items-center justify-content-center py-2 px-3"
                      @click="selectAllProducts">
                    <i class="font-size-20 mr-2"
                       :class="isSameProductIds() ? 'far fa-check-square' : 'far fa-square'"/>
                    <div class="text font-weight-bold">
                      Zaznacz wszystkie
                    </div>
                  </b-button>
                  <b-button variant="primary" class="w-xxl" @click="Swal.fire('Dostępne wkrótce')">
                    Dostosuj dane
                  </b-button>
                </div>

                <div class="col-sm-12 col-md-6 d-inline-flex justify-content-end">

                  <div class="dataTables_paginate paging_simple_numbers">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"/>
                    </ul>
                  </div>
                  <div class="align-items-center">
                    <label class="d-inline-flex align-items-center mx-2 mb-0">
                      <b-form-input v-model="table.inputPage" class="form-control form-control-sm ml-2 form-xs"/>
                    </label>
                    <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, table.inputPage)">Idź do
                      strony
                    </b-button>
                  </div>
                </div>
              </div>

              <template v-if="loading">
                <div class="text-center">
                  <h5>{{ $t('message.loading') }}</h5>
                  <span aria-hidden="true" class="m-2 spinner-border text-primary"/>
                </div>
              </template>
              <template v-else>
                <template v-if="shopProducts.length === 0">
                  <div class="col-lg-12 text-center product-body py-3 mb-2">
                    Brak danych
                  </div>
                </template>
                <template v-else>
                  <div class="row my-3">
                    <virtual-list
                        :keeps="10"
                        :estimate-size="15"
                        :page-mode="true"
                        style="width: 100%"
                        data-key="id"
                        :data-sources="shopProducts"
                        :data-component="shopProductCard"
                        :extra-props="{
                          'marketplace': filtering.currentMarketPlace,
                          'productIds': form.productIds,
                          'categories': categories,
                          'warehouses': warehouses,
                          'taxes': taxes,
                          'delivery-units': deliveryUnits,
                          'shipping-costs': shippingCosts,
                          'modifyTimestamps': modifyTimestamps
                        }"
                        @select-or-unselect-product="selectOrUnSelectProduct"
                        @open-images-modal="selectProductImages"
                        @open-price-details-modal="$refs.productPriceDetailsModal.openModal"
                        @send-product="sendOneProduct"
                        @open-advanced-product-details-modal="$refs.advancedProductDetailModal.openModal"
                    />
                    <!--                  <div v-for="(product, index) in shopProducts" :key="index" class="col-xl-12 mb-2 rounded-5">-->
                    <!--                    <div class="">-->
                    <!--                      <shop-product-card-->
                    <!--                          :product="product"-->
                    <!--                          :product-ids="form.productIds"-->
                    <!--                          :categories="categories"-->
                    <!--                          :warehouses="warehouses"-->
                    <!--                          :taxes="taxes"-->
                    <!--                          :delivery-units="deliveryUnits"-->
                    <!--                          :shipping-costs="shippingCosts"-->
                    <!--                          @select-or-unselect-product="selectOrUnSelectProduct(product)"-->
                    <!--                          @open-images-modal="selectProductImages"-->
                    <!--                      />-->
                    <!--                    </div>-->
                    <!--                  </div>-->
                    <!--                  @open-details-modal="$refs.productDetailsModal.openModal"-->
                    <!--                  @open-duplicate-product-modal="$refs.depotDuplicateProductModal.openModal"-->
                  </div>
                </template>
              </template>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  {{
                    $t('table.entries-footer', {
                      'amount': paginationHelper.getElementsAmount(table),
                      'elements': paginationHelper.getElementsCount(table),
                      'all': table.totalRows
                    })
                  }}
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded">
                      <!-- pagination -->
                      <b-pagination v-model="table.currentPage" :per-page="table.perPage"
                                    :total-rows="table.rows"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <product-price-detail-modal ref="productPriceDetailsModal"/>
    <advanced-product-detail-modal
        :shop-id="getSelectedShopId"
        :categories="categories"
        :warehouses="warehouses"
        :taxes="taxes"
        :delivery-units="deliveryUnits"
        :shipping-costs="shippingCosts"
        ref="advancedProductDetailModal"
    />

    <shop-product-limit-modal ref="shopProductLimitModal"/>
    <add-products-to-shop-modal
        ref="addProductsToShopModal"
        @open-shop-product-limit-modal="value => $refs.shopProductLimitModal.openModal(value)"
        @load-products-count="loadAllProductsCount"
    />

    <b-modal
        id="modal-3"
        v-model="tableColumns.visibleModal"
        title="Wybierz kolumny do pokazania/ukrycia"
        title-class="font-18"
        hide-footer
        @hide="tableColumns.visibleModal = false"
        @esc="tableColumns.visibleModal = false">
      <form>
        <div class="form-group">
          <!--          <label class="mx-2"><input v-model="tableColumns.id" type="checkbox" /> ID</label>-->
          <label class="mx-2"><input v-model="tableColumns.sku" type="checkbox"/> SKU</label>
          <label class="mx-2"><input v-model="tableColumns.image" type="checkbox"/> Zdjęcie</label>
          <label class="mx-2"><input v-model="tableColumns.name" type="checkbox"/> Nazwa produktu</label>
          <label class="mx-2"><input v-model="tableColumns.categoryName" type="checkbox"/> Nazwa kategorii</label>
          <label class="mx-2"><input v-model="tableColumns.ean" type="checkbox"/> EAN</label>
          <label class="mx-2"><input v-model="tableColumns.profitMargin" type="checkbox"/> Narzut</label>
          <label class="mx-2"><input v-model="tableColumns.priceHigh" type="checkbox"/> Cena sklepu brutto</label>
          <label class="mx-2"><input v-model="tableColumns.priceWholesaleHigh" type="checkbox"/> Cena hurtowni
            netto</label>
          <label class="mx-2"><input v-model="tableColumns.expect" type="checkbox"/> Dozwolony do sprzedaży na
            Allegro</label>
          <label class="mx-2"><input v-model="tableColumns.totalWarehouseStock" type="checkbox"/> Ilość sztuk</label>
          <label class="mx-2"><input v-model="tableColumns.warehouse" type="checkbox"/> Hurtownia</label>
          <label class="mx-2"><input v-model="tableColumns.tax" type="checkbox"/> Stawka podatku</label>
          <label class="mx-2"><input v-model="tableColumns.deliveryTime" type="checkbox"/> Czas dostawy</label>
          <!--          <label class="mx-2"><input v-model="tableColumns.industry" type="checkbox" /> Branża</label>-->
          <label class="mx-2"><input v-model="tableColumns.shippingCost" type="checkbox"/> Koszt dostawy</label>
        </div>
      </form>
    </b-modal>

    <!-- Modal -->
    <b-modal
        id="modal-4"
        :scrollable="true"
        v-model="modals.editProduct.visibleModal"
        title="Edytowanie wybranych produktów"
        title-class="font-18"
        hide-footer
        @hide="hideEditProductModal"
        @esc="hideEditProductModal"
        @shown="refreshSlidebar">
      <form>

        <div class="form-group">
          <ecat-multiselect @change="value => modals.editProduct.form.categoryId = value"
                            view-id="name"
                            save-id="id"
                            :can-unselect="true"
                            label="Wybierz kategorie"
                            :load-url="`/shop/${this.getSelectedShopId}/category/list/pagination`"
                            :query-url="`/shop/${this.getSelectedShopId}/category/by/name`"
                            param="name">
          </ecat-multiselect>
        </div>

        <div class="form-group">
          <label for="price-increase-value">{{ $t('products.add.increase-profit-by-price') }}</label>
          <input id="price-increase-value" v-model="modals.editProduct.priceIncreaseValue" type="text"
                 name="price-increase-value" class="form-control"/>
        </div>

        <div class="form-group">
          <label for="price-increase-percent">{{ $t('products.add.increase-profit-by-percent') }}</label>
          <input id="price-increase-percent" v-model="modals.editProduct.form.priceIncreasePercent" type="text"
                 name="price-increase-percent" class="form-control"/>
        </div>

        <div class="form-group">
          <label for="profit-lowered-by-percent">{{ $t('products.add.reduce-profit') }}</label>
          <vue-slide-bar id="profit-lowered-by-percent" ref="slidebar"
                         v-model="modals.editProduct.profitLoweredByPercent" :min="1" :max="50"/>
        </div>

        <div class="text-right">
          <b-button @click="editProducts" variant="success">{{ $t('message.save') }}</b-button>
          <b-button class="ml-1" variant="danger" @click="hideEditProductModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

    <!-- Modal -->
    <b-modal
        id="modal-5"
        :scrollable="true"
        v-model="modals.addToAllegro.visible"
        title="Wystawianie produktów na Allegro"
        title-class="font-18"
        hide-footer
        @hide="hideModalAddToAllegro"
        @esc="hideModalAddToAllegro">
      <form>

        <div class="form-group" id="allow-listing-in-paid-categories-info">
          <div class="d-inline-flex">
            <div class="custom-control custom-checkbox">
              <input id="allow-listing-in-paid-categories"
                     v-model="modals.addToAllegro.form.allowListingInPaidCategories" type="checkbox"
                     class="custom-control-input"/>
              <label for="allow-listing-in-paid-categories" class="custom-control-label">Czy chcesz wystawić produkty z
                płatnych kategorii?</label>
            </div>

            <i class="ri ri-information-line feather feather-info mx-2 font-size-16"/>
          </div>
        </div>

        <b-tooltip target="allow-listing-in-paid-categories-info" placement="bottom">Allegro nalicza dodatkowe opłaty za
          wystawianie produktów w niektórych kategoriach. Niektóre opłaty potrafią wynosić aż 5 złotych od aukcji.
          Więcej informacji dostępne na: <a href="https://help.allegro.com/fees/pl#oplata-za-wystawienie">https://help.allegro.com/fees/pl#oplata-za-wystawienie</a>
        </b-tooltip>

        <div class="from-group">
          <label>Wyślij również na:</label>

          <div class="custom-control custom-checkbox mb-3">
            <input id="CZECH_REPUBLIC" value="CZECH_REPUBLIC"
                   @change="selectOrUnselectAllegroMarketplace('CZECH_REPUBLIC')"
                   :checked="$store.getters['allegro/getSelectedMarketplaces'].includes('CZECH_REPUBLIC')"
                   type="checkbox" class="custom-control-input"/>
            <label for="CZECH_REPUBLIC" class="custom-control-label">Allegro Czechy</label>
          </div>

          <div class="custom-control custom-checkbox mb-3">
            <input id="SLOVAKIA" value="SLOVAKIA" @change="selectOrUnselectAllegroMarketplace('SLOVAKIA')"
                   :checked="$store.getters['allegro/getSelectedMarketplaces'].includes('SLOVAKIA')" type="checkbox"
                   class="custom-control-input"/>
            <label for="SLOVAKIA" class="custom-control-label">Allegro Słowacja</label>
          </div>
        </div>

        <div class="text-center">
          <span>Wybrane produkty zostaną wystawione na Allegro, dane zostaną pobrane z katalogu na podstawie EAN lub nazwy produktu.</span>
          <p class="text-danger">{{ $t('products.modal.warning.1') }} {{ $t('products.modal.warning.2') }}</p>
        </div>

        <!--        <div class="form-group">-->
        <!--          <ecat-multiselect-->
        <!--              @change="value => modals.addToAllegro.form.allegroAccountId = value"-->
        <!--              label="Nazwa konta allegro"-->
        <!--              placeholder="Wybierz konto allegro"-->
        <!--              :load-url="`/allegro/list/pagination/${getSelectedShopId}`"-->
        <!--              :query-url="`/allegro/by-name/${getSelectedShopId}`"-->
        <!--              save-id="id"-->
        <!--              view-id="name"-->
        <!--              param="name"-->
        <!--              :class="{ 'is-invalid': submitted && $v.modals.addToAllegro.form.allegroAccountId.$error }"-->
        <!--          />-->

        <!--          <div v-if="submitted && !$v.modals.addToAllegro.form.allegroAccountId.required" class="invalid-feedback">-->
        <!--            {{ $t('message.required') }}-->
        <!--          </div>-->
        <!--        </div>-->

        <!--      <div class="form-group">-->
        <!--        <label>{{ $t('products.modal.delivery.title') }}</label>-->

        <!--        <div>-->
        <!--          <input type="radio" id="SMART" value="SMART" v-model="modals.addToAllegro.form.priceType" name="SMART"-->
        <!--                 :class="{ 'is-invalid': $v.modals.addToAllegro.form.priceType.$error }">-->
        <!--          <label for="SMART" style="margin-left: 5px;">{{ $t('products.modal.delivery.smart') }}</label>-->
        <!--        </div>-->

        <!--        <div>-->
        <!--          <input type="radio" id="WAREHOUSE" value="WAREHOUSE" v-model="modals.addToAllegro.form.priceType"-->
        <!--                 name="SMART" :class="{ 'is-invalid': $v.modals.addToAllegro.form.priceType.$error }">-->
        <!--          <label for="WAREHOUSE" style="margin-left: 5px;">{{ $t('products.modal.delivery.warehouse') }}</label>-->
        <!--        </div>-->

        <!--        <div v-if="submitted && !$v.modals.addToAllegro.form.priceType.required" class="text-danger">-->
        <!--          {{ $t('message.required') }}-->
        <!--        </div>-->

        <!--        <p class="text-danger">Cena produktów może zostać podwyższona celem zmaksymalizowania zysku ze sprzedaży-->
        <!--          produktu ze względu na wybraną opcję dostawy</p>-->
        <!--      </div>-->

        <div class="form-group">
          <div class="custom-control custom-checkbox mb-3">
            <input id="only-products-in-range" v-model="modals.addToAllegro.form.onlyProductsInRange" type="checkbox"
                   class="custom-control-input"/>
            <label for="only-products-in-range"
                   class="custom-control-label">{{ $t('products.modal.only-products-in-range') }}</label>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-6" v-if="modals.addToAllegro.form.onlyProductsInRange">
            <label for="minPrice">{{ $t('products.modal.price.min') }}</label>
            <div class="input-group">
              <span class="input-group-btn input-group-prepend">
                <button @click="decrementMinPrice" class="btn btn-danger" type="button"><svg
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-minus"><line x1="5" y1="12" x2="19" y2="12"></line></svg></button>
              </span>
              <input type="number" inputmode=”numeric” class="form-control"
                     v-model.number="modals.addToAllegro.form.minPrice" name="minPrice" min="1">
              <span class="input-group-btn input-group-append">
                <button @click="modals.addToAllegro.form.minPrice += 0.50;" class="btn btn-success" type="button"><svg
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12"
                                                                                                   x2="19"
                                                                                                   y2="12"></line></svg>
                </button>
              </span>
            </div>
          </div>

          <div class="form-group col-6" v-if="modals.addToAllegro.form.onlyProductsInRange">
            <label for="maxPrice">{{ $t('products.modal.price.max') }}</label>
            <div class="input-group">
              <span class="input-group-btn input-group-prepend">
                <button @click="decrementMaxPrice" class="btn btn-danger" type="button"><svg
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-minus"><line x1="5" y1="12" x2="19" y2="12"></line></svg></button>
              </span>
              <input type="number" inputmode=”numeric” class="form-control"
                     v-model.number="modals.addToAllegro.form.maxPrice" name="maxPrice" min="1">
              <span class="input-group-btn input-group-append">
                <button @click="modals.addToAllegro.form.maxPrice += 0.50;" class="btn btn-success" type="button"><svg
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12"
                                                                                                   x2="19"
                                                                                                   y2="12"></line></svg>
                </button>
              </span>
            </div>
          </div>
        </div>

        <div class="text-right">
          <ecat-button :callback="addProductsToAllegro" variant="success" :message="$t('message.save')"/>
          <b-button class="ml-1" variant="danger" @click="hideModalAddToAllegro">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

    <b-modal
        id="modal-5"
        v-model="modals.hideShopCategory.visible"
        title="Ukrywanie całej kategori sklepu"
        title-class="font-18"
        hide-footer
        @hide="hideModalHideShopCategory"
        @esc="hideModalHideShopCategory">
      <form>
        <div class="form-group">
          <ecat-multiselect @change="value => modals.hideShopCategory.form.categoryId = value"
                            view-id="name"
                            save-id="id"
                            :can-unselect="true"
                            label="Kategoria sklepu"
                            placeholder="Wybierz kategorię"
                            :load-url="`/shop/${this.getSelectedShopId}/category/list/pagination`"
                            :query-url="`/shop/${this.getSelectedShopId}/category/by/name`"
                            param="name"
                            :class="{ 'is-invalid': submitted && $v.modals.hideShopCategory.form.categoryId.$error }"
          />
          <div v-if="submitted && !$v.modals.hideShopCategory.form.categoryId.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="text-right">
          <b-button @click="hideShopCategory" variant="danger">Ukryj</b-button>
          <b-button class="ml-1" variant="secondary" @click="hideModalHideShopCategory">{{
              $t('message.cancel')
            }}
          </b-button>
        </div>
      </form>
    </b-modal>

  </div>
</template>